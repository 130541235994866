import { subscribeTo } from '../sharedStore';
import isEqual from 'fast-deep-equal';

(function initTitleResize() {
  const assertElementFitsWidth = () =>
    titleChildEl().offsetWidth <= titleParentEl().offsetWidth;
  const assertElementFitsHeight = () =>
    titleChildEl().offsetHeight <= titleParentEl().offsetHeight;

  const prepFontOptions = (fontSize) => {
    const defOpts = { fontSize, paddingRight: 3 };

    if (fontSize > 71) {
      return {
        ...defOpts,
        lineHeight: fontSize * 0.25 + fontSize,
      };
    } else if (fontSize > 65) {
      return {
        ...defOpts,
        lineHeight: fontSize * 0.27 + fontSize,
      };
    } else if (fontSize > 46) {
      return {
        ...defOpts,
        lineHeight: fontSize * 0.28 + fontSize,
      };
    } else if (fontSize > 37) {
      return {
        ...defOpts,
        lineHeight: fontSize * 0.32 + fontSize,
      };
    } else if (fontSize > 32) {
      return {
        ...defOpts,
        lineHeight: fontSize * 0.37 + fontSize,
      };
    } else if (fontSize > 27) {
      return {
        ...defOpts,
        lineHeight: fontSize * 0.36 + fontSize,
      };
    } else if (fontSize > 23) {
      return {
        ...defOpts,
        lineHeight: 33.2,
        paddingRight: 2,
      };
    } else if (fontSize > 19) {
      return {
        ...defOpts,
        lineHeight: 29.2,
        paddingRight: 2,
      };
    } else if (fontSize > 17) {
      return {
        ...defOpts,
        lineHeight: 24,
        paddingRight: 1,
      };
    } else if (fontSize > 15) {
      return {
        ...defOpts,
        lineHeight: 22.4,
        paddingRight: 1,
      };
    } else if (fontSize > 13) {
      return {
        ...defOpts,
        lineHeight: 20.4,
        paddingRight: 1,
      };
    } else {
      return {
        ...defOpts,
        lineHeight: 18.4,
        paddingRight: 1,
      };
    }
  };
  const prepStyles = ({ fontSize, lineHeight, ready, paddingRight }) => `
    #TitleMainWrapper {
      position: absolute;
      top: ${config.mobileTop}%;
      left: ${config.mobileLeft}%;
      height: ${config.height < 0 ? 0 : config.height}%;
      width: ${config.width}%;
      overflow-y: hidden;
    }
    #TitleMainWrapper #TitleParent {
      font-size: ${fontSize}px;
      line-height: ${lineHeight}px;
      overflow: ${ready ? 'inherit' : 'hidden'};
      height: 100%;
      opacity: ${ready ? 1 : 0.01} !important;
    }

    #TitleMainWrapper #TitleParent span {
      padding-right: ${paddingRight}px;
    }

    #TitleMainWrapper #TitleParent #TitleChild {
      display: ${ready ? 'block' : 'inline-block'};
    }
    `;
  const setStyles = (fontSize, { ready }) => {
    const options = prepFontOptions(fontSize);
    const styles = prepStyles({ ...options, ready });

    stylesEl.innerHTML = styles;
  };

  const resizeFont = (currentPid, maxFontSize) => {
    setTimeout(() => {
      for (let fontSize = maxFontSize; fontSize >= config.fontMin; fontSize--) {
        if (config.pid !== currentPid) {
          break;
        }

        if (fontSize === config.fontMin) {
          setStyles(fontSize, {
            ready: true,
          });
          break;
        }

        setStyles(fontSize, { ready: false });

        const isFit = assertElementFitsHeight() && assertElementFitsWidth();

        if (isFit) {
          setStyles(fontSize, {
            ready: true,
          });
          titleMainEl().style.overflow = 'hidden';
          break;
        }
      }
    }, 0);
  };

  const prepConfig = (val) => {
    const {
      positionBottom,
      positionLeft,
      positionRight,
      positionTop,
      titleElements,
    } = val;
    let { fontSizeMax, fontSizeMin } = val;
    const paragraphEl = document.querySelector('#TitleChild p');

    if (document.querySelector('#TitleChild h1')) {
      // Size of h1 is 2em, because of that max font size
      // should be such one to make h1 to be with max font size
      fontSizeMax = parseInt(fontSizeMax / 2);

      if (!paragraphEl) {
        // Size of h1 is 2em, because of that min font size
        // should be such one to make h1 to be with min font size
        fontSizeMin = parseInt(fontSizeMin / 2);
      }
    } else if (document.querySelector('#TitleChild h2')) {
      fontSizeMax = parseInt(fontSizeMax / 1.5);

      if (!paragraphEl) {
        fontSizeMin = parseInt(fontSizeMin / 1.5);
      }
    } else if (document.querySelector('#TitleChild h3')) {
      fontSizeMax = parseInt(fontSizeMax / 1.17);

      if (!paragraphEl) {
        fontSizeMin = parseInt(fontSizeMin / 1.17);
      }
    } else if (!paragraphEl) {
      fontSizeMin = parseInt(fontSizeMin / 1.5);
    }

    return {
      width: 100 - parseInt(positionLeft) - parseInt(positionRight),
      height: 100 - parseInt(positionTop) - parseInt(positionBottom),
      mobileTop: parseInt(positionTop),
      mobileLeft: parseInt(positionLeft),
      fontMin: parseInt(fontSizeMin),
      fontMax: parseInt(fontSizeMax),
      titleElements,
    };
  };

  const titleMainEl = () => document.getElementById('TitleMainWrapper');
  const titleParentEl = () => document.getElementById('TitleParent');
  const titleChildEl = () => document.getElementById('TitleChild');
  const stylesEl = document.createElement('style');
  document.head.appendChild(stylesEl);
  const config = { pid: 0 };
  const updateIfChanged = (val) => {
    const nextConf = Object.assign({}, val);
    const el = titleChildEl();
    if (el) {
      nextConf.html = el.innerHTML;
    }

    if (!isEqual(config, nextConf)) {
      Object.assign(config, nextConf);
      config.pid++;

      resizeFont(config.pid, config.fontMax);
    }
  };

  subscribeTo('titleSetting', (val) => {
    setTimeout(() => {
      updateIfChanged(prepConfig(val));
    }, 0);
  });

  setInterval(() => {
    updateIfChanged(config);
  }, 300);
})();
